@font-face {
	font-family: 'Zuume Cut';
	font-weight: 200;
	src: url(./zuume_cut/zuume_extralight.ttf) format('truetype');
}
@font-face {
	font-family: 'Zuume Cut';
	font-weight: 200;
	font-style: italic;
	src: url(./zuume_cut/zuume_extralight_italic.ttf) format('truetype');
}
@font-face {
	font-family: 'Zuume Cut';
	font-weight: 300;
	src: url(./zuume_cut/zuume_light.ttf) format('truetype');
}
@font-face {
	font-family: 'Zuume Cut';
	font-weight: 300;
	font-style: italic;
	src: url(./zuume_cut/zuume_light_italic.ttf) format('truetype');
}
@font-face {
	font-family: 'Zuume Cut';
	font-weight: 400;
	src: url(./zuume_cut/zuume_regular.ttf) format('truetype');
}
@font-face {
	font-family: 'Zuume Cut';
	font-weight: 400;
	font-style: italic;
	src: url(./zuume_cut/zuume_italic.ttf) format('truetype');
}
@font-face {
	font-family: 'Zuume Cut';
	font-weight: 500;
	src: url(./zuume_cut/zuume_medium.ttf) format('truetype');
}
@font-face {
	font-family: 'Zuume Cut';
	font-weight: 500;
	font-style: italic;
	src: url(./zuume_cut/zuume_medium_italic.ttf) format('truetype');
}
@font-face {
	font-family: 'Zuume Cut';
	font-weight: 600;
	src: url(./zuume_cut/zuume_semibold.ttf) format('truetype');
}
@font-face {
	font-family: 'Zuume Cut';
	font-weight: 600;
	font-style: italic;
	src: url(./zuume_cut/zuume_semibold_italic.ttf) format('truetype');
}
@font-face {
	font-family: 'Zuume Cut';
	font-weight: 700;
	src: url(./zuume_cut/zuume_bold.ttf) format('truetype');
}
@font-face {
	font-family: 'Zuume Cut';
	font-weight: 700;
	font-style: italic;
	src: url(./zuume_cut/zuume_bold_italic.ttf) format('truetype');
}
@font-face {
	font-family: "Zuume Cut";
	font-weight: 800;
	src: url(./zuume_cut/zuume_extrabold.ttf) format('truetype');
}
@font-face {
	font-family: 'Zuume Cut';
	font-weight: 800;
	font-style: italic;
	src: url(./zuume_cut/zuume_extrabold_italic.ttf) format('truetype');
}
@font-face {
	font-family: 'Zuume Cut';
	font-weight: 900;
	src: url(./zuume_cut/zuume_black.ttf) format('truetype');
}
@font-face {
	font-family: 'Zuume Cut';
	font-weight: 900;
	font-style: italic;
	src: url(./zuume_cut/zuume_black_italic.ttf) format('truetype');
}