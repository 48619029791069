@tailwind base;
@tailwind components;
@tailwind utilities;

@import './assets/fonts/roboto.scss';
@import './assets/fonts/zuume_cut.scss';

html { font-size: 11pt; }

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

h1 { @apply text-2xl font-bold;     }
h2 { @apply text-xl font-semibold; }
h3 { @apply text-lg font-semibold; }
h4 { @apply text-lg  font-medium;   }
h5 { @apply font-medium;   }

a { @apply hover:underline; }